import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import { Link } from 'react-router-dom';
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function Market_trend() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  







      




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data); 
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  


  function getTrendBackground(status) {
    switch (status) {
      case 'up':
        return 'bg-green-status';
      case 'down':
        return 'bg-red-status';
      case 'side':
        return 'bg-yellow-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }

  function getTrendIcon(status) {
    switch (status) {
      case 'up':
        return 'arrow-up-circle';
      case 'down':
        return 'arrow-down-circle';
      case 'side':
        return 'arrow-right-circle';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }
  

  useEffect(() => {
    // Replace icons after component is mounted

    

    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if(data == null)
    {
      return (
       <>  </>
       )
    }

  
  return (

    
    <div>
       

       <div className="auth-header" style={{height:'150px'}}>
      <div>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <button style={{
                  padding: '8px 16px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}>X</button>
              </Link>
            </div>
        
        <div className="auth-content" style={{justifyContent:'center'}}>
          <div>
            <center>
            <h2>Market Trends</h2>
            <h4 className="p-0"></h4>
            </center></div>
        </div>
      </div>
        <section>
          <div className="custom-container">
          
            <div className="row gy-3">
            {data.market_trend.map((trend, index) => (
              <div className="col-12">
                <div className="transaction-box">
                  <a href="#" className="d-flex gap-3">
                    <div className={`transaction-image1 ${getTrendBackground(trend.trend)}`}>
                    <i className="service-icon" data-feather={getTrendIcon(trend.trend)} />
                    </div>
                    <div className="transaction-details">
                      <div className="transaction-name">
                        <h2>{trend.sector_of_trend}</h2>
                        <h6 className={`uppercase`}>{trend.trend}</h6>
                      </div>
                      
                      {/* <div className="d-flex justify-content-between">
                        <h5 className="light-text">Subscription</h5>
                        <h5 className="light-text">8:45 am</h5>
                      </div> */}
                    </div>
                  </a>
                </div>
              </div>
              ))}

              
            </div>
          </div>
        </section>
        {/* all cards section end */}
       
        {/* saving plans section starts */}
        
       
        
        
      </div>
  );
}

export default Market_trend;
