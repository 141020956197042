import logo from './assets/theme_asset/logo.png';

import feather from 'feather-icons';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Link } from 'react-router-dom';

import { BiSolidLeftArrow } from 'react-icons/bi';


import token1 from './assets/theme_asset/token/21.png';
import token2 from './assets/theme_asset/token/22.png';
import token3 from './assets/theme_asset/token/23.png';
import token4 from './assets/theme_asset/token/24.png';
import token5 from './assets/theme_asset/token/25.png';
import token6 from './assets/theme_asset/token/26.png';
import token7 from './assets/theme_asset/token/27.png';
import token8 from './assets/theme_asset/token/28.png';
import token9 from './assets/theme_asset/token/29.png';
import token10 from './assets/theme_asset/token/30.png';
import color1 from './assets/theme_asset/token/color1.png';
import color2 from './assets/theme_asset/token/color2.png';
import color3 from './assets/theme_asset/token/color3.png';
import deposite1 from './assets/theme_asset/deposit.png'
import reward from './assets/theme_asset/reward.png'

import Footer from './footer';


import './game.css';

import { useEffect } from 'react';

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Pagination from './react-pegination';






function Team() {

  const navigate = useNavigate();


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  
  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/userteamapi/${id}?page=${pageNumber + 1}`);
      setData(response.data);
    
    } catch (error) {
      setError(error);
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage3');
      }

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/team');
    }
    feather.replace();

    fetchData();
  }, []);


  
  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={false} showFooter={false} /> 

       )
    }

    const formatDate = (dateString) => {
      const months = [
        'Jan', 'Feb', 'March', 'April', 'May', 'June',
        'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
      ];
  
      const date = new Date(dateString);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
  
      return `${day} ${month} ${year}`;
    };
  
  
 
    return (


      <body className=''>
      <div >
          

      <div className="custom-container">
      <div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-150px'}}>
       <Link to="/" >
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{height:'60px'}}/>
          </div>
          </div>
    </div>
       
      
    <section>
  <div className="custom-container">
    <div className="title" style={{justifyContent:'center'}}>
        <center><h2 >My Team</h2></center>
    </div>
    {data.my_downline && data.my_downline.length > 0 ? (
  <section>
    <div className="custom-container">
      
      {data.my_downline.map((downline, index) => (
        <div key={index} className="col-12">
          <div className='report-box'>
        <div>
        <div style={{ 
            fontSize: '13px', 
            whiteSpace: 'nowrap', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis' ,
            width:'130px'
          }}>
            {downline.first_name}
          </div>
          <div style={{fontSize:'13px'}}>{downline.user_id}</div>
        </div>
        <div>
            <div className='text-center' style={{fontSize:'13px'}}>{downline.is_active} (level {downline.level})</div>
            <div  style={{fontSize:'13px'}}>{formatDate(downline.joining_date)}</div>
        </div>
        <div>
        <div className='text-center' style={{fontSize:'13px'}}><b>Sponcer</b></div>
        <div className='text-center' style={{fontSize:'13px'}}>{downline.sponcer_id}</div>
        </div>
      </div>
          
        </div>
      ))}
      
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No downline data available</div>
)}


      <Footer />    
       </div>
       </section>
       </div>
       </body>
  );
  }

  export default Team;
