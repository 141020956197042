import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom';

import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';






function App() {

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  
  

  



const dataChartRec = {
  labels: [
    'Active',
    'Hold',
    'Exit'
  ],
  datasets: [{
    label: 'Recommandation Insights',
    data: recMetrics,
    backgroundColor: [
      '#2b8b64',
      '#d18925',
      'black'
    ],
    hoverOffset: 4
  }]
};


      

     // console.log(apiBaseUrl);



  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
      var tArr = [response.data.recommandation_count.active,response.data.recommandation_count.hold,response.data.recommandation_count.exit]; 
      setRecMetrics(tArr)
      console.log(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  function getStatusClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green-status';
      case 'short':
        return 'bg-red-status';
      case 'hold':
        return 'bg-yellow-status';
      case 'exit':
        return 'bg-black-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }


  function getBackgroundClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green';
      case 'short':
        return 'bg-red';
      case 'hold':
        return 'bg-yellow';
      case 'exit':
        return 'bg-black';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }


  function getTrendBackground(status) {
    switch (status) {
      case 'up':
        return 'bg-green-status';
      case 'down':
        return 'bg-red-status';
      case 'side':
        return 'bg-yellow-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }

  function getTrendIcon(status) {
    switch (status) {
      case 'up':
        return 'arrow-up-circle';
      case 'down':
        return 'arrow-down-circle';
      case 'side':
        return 'arrow-right-circle';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }
  
  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage3');
      }

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/insights');
    }
    console.log(token)
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    
      <SkeletonLoader showHeader={false} showFooter={true} />     
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader />
       )
    }

   
  
 
    return (
      <div>
        <div className="auth-header" style={{height:'150px'}}>
      <div>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <button style={{
                  padding: '8px 16px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}>X</button>
              </Link>
            </div>
        
        <div className="auth-content" style={{justifyContent:'center'}}>
          <div>
            <center>
            <h2>Analysis Insights</h2>
            <h4 className="p-0"></h4>
            </center></div>
        </div>
      </div>
        
<section>
    <div className="custom-container">
    
    <div>
    <div className='p6 col-md-6'>   
      <Doughnut data={dataChartRec} />
    </div>
    <div className='p3 col-md-6'>
    <h2>Performance Metrix</h2>
    <div className="bill-box mb-3 bg-light mt-3">
                  <div className="">
                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 16v2a1 1 0 0 1-1 1H6l6-7l-6-7h11a1 1 0 0 1 1 1v2" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Total Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data.recommandation_count.all}</h2>
                    </div>
                    </div>

                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M1.5 8a6.5 6.5 0 1 0 13 0a6.5 6.5 0 0 0-13 0m4.879-2.773l4.264 2.559a.25.25 0 0 1 0 .428l-4.264 2.559A.25.25 0 0 1 6 10.559V5.442a.25.25 0 0 1 .379-.215" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Active Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data.recommandation_count.active}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
	<path fill="black" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32M7 6h2v8H7zm4 0h2v8h-2z" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Hold Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data.recommandation_count.hold}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
	<path fill="black" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m1.41-1.41A8 8 0 1 0 15.66 4.34A8 8 0 0 0 4.34 15.66m9.9-8.49L11.41 10l2.83 2.83l-1.41 1.41L10 11.41l-2.83 2.83l-1.41-1.41L8.59 10L5.76 7.17l1.41-1.41L10 8.59l2.83-2.83z" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Exit Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data.recommandation_count.exit}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<path d="M5 19L19 5" />
		<circle cx="7" cy="7" r="3" />
		<circle cx="17" cy="17" r="3" />
	</g>
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Success Ratio</h2>
                    </div>

                    <div className="float-right mt-2">
                    <h2>{data.success_ratio.toFixed(2)}</h2>
                    </div>
                    </div>

                  </div>
                  
                </div>
      
    </div>
    </div>
    </div>
  </section>
  <div className="custom-container">

  {data.exit_calls && data.exit_calls.length > 0 && (
  <section>
{data.exit_calls.map((exit_calls, index) => (<div className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
<div className="d-flex gap-3">
<div className="bill-icon">
<img className="img-fluid icon" src={apiImageUrl+'/recommandation_logo/'+exit_calls.logo} alt="p6" />
</div>
<div className="bill-details">
<h6 className="dark-text">{exit_calls.script_name} |<span className="text-muted"> {exit_calls.stock_type}</span></h6>
<h6 className="light-text mt-2"><span className="">BZ: (₹{exit_calls.price_band_from} - ₹{exit_calls.price_band_to})</span> | {exit_calls.duration}</h6>
</div>
</div>
<div className="bill-price">
<h5>T: ₹{exit_calls.target} | SL: ₹{exit_calls.stoploss} </h5>
<a href="#pay" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(exit_calls.status)}`}>{exit_calls.status}</a>
</div>
</div>))} 
</section>
)}
</div>
   
        {/* news-update section end */}
        {/* panel-space start */}
        <section className="panel-space" />
        {/* panel-space end */}
        {/* bottom navbar start */}
       
        {/* bottom navbar end */}
        {/* add money modal start */}
        <Footer/>
     
        
      </div>
  );
}



export default App;
