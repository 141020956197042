import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';
import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import SkeletonLoader from './SkeletonLoader';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Link, useLocation  } from 'react-router-dom';

import './recommandation.css';

function Recommendation1() {
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterType, setFilterType] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState('all');

  
useEffect(() => {

  
  const params = new URLSearchParams(location.search);
  const status = params.get('status') || 'all'; // Use 'status' instead of 'type'
  const type = params.get('type') || 'all';

  setFilterStatus(status); // Update filter status
  setFilterType(type);

  handleSort(filterStatus, filterType);
  
}, []);



useEffect(() => {
  handleSort(filterStatus,filterType)
},[originalData]);


  const fetchData = async () => {
    try {
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
      setOriginalData(response.data);

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      handleSort(filterStatus,filterType)
    }
  };
  
  useEffect(() => {
    fetchData();
    
    const params = new URLSearchParams(location.search);
   

   
}, [location.search]);



const handleSort = (status, type) => {
    let filtered;

    if (originalData && originalData.recommandation) {
        if (status === 'all' && type === 'all') {
            filtered = originalData.recommandation;
        } else {
            filtered = originalData.recommandation.filter(recommendation => {
                return (status === 'all' || recommendation.status === status) && 
                       (type === 'all' || recommendation.recommandation_type === type);
            });
        }
        setData(prevData => ({ ...prevData, recommandation: filtered })); // Update data state
    }
}

const selectRef = useRef(null);

  const handleSvgClick = () => {
    if (selectRef.current) {
      selectRef.current.click();
    }
  };

  if (loading) {
    return (
        
      <SkeletonLoader showHeader={false} showFooter={false} /> 

    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (data === null) {
    return null;
  }

  const RecommendationCard = ({ recommendation }) => {
    const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    const getBackgroundClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green';
        case 'short':
          return 'bg-red';
        case 'hold':
          return 'bg-yellow';
        case 'exit':
          return 'bg-black';
        default:
          return '';
      }
    };

    const getStatusClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green-status';
        case 'short':
          return 'bg-red-status';
        case 'hold':
          return 'bg-yellow-status';
        case 'exit':
          return 'bg-black-status';
        default:
          return '';
      }
    };

    const percentage = (data.user.capital)*(data.user.percentage/100);
  const percentage1 = (data.user.capital_future)*(data.user.percentage_future/100);
  const percentage2 = (data.user.capital_option)*(data.user.percentage_option/100);

    const quantity = recommendation.stock_type === 'options'
  ? Math.max(0, Math.floor(percentage2 / (recommendation.price_band_to - recommendation.stoploss)))
  : recommendation.stock_type === 'futures'
  ?  Math.max(0, Math.floor(percentage1 / (recommendation.price_band_to - recommendation.stoploss)))
  :  Math.max(0, Math.floor(percentage / (recommendation.price_band_to - recommendation.stoploss)))


  return (
    <div>
       { selectedTab === 'all' && (
      <div>
      <Link to={`/recomm_details/${recommendation.id}`}>
        <div className="card-box mt-3">
          <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
            <div className="d-flex justify-content-between">
              <h5 className="fw-semibold">{recommendation.script_name}</h5>
              <img className="rec_img" src={apiImageUrl + '/recommandation_logo/' + recommendation.logo} alt="ellipse" />
            </div>
            <div className="">
              <div className="text-start">
                <div className="">
                  <div className='d-flex justify-content-between'>
                    <h3 className="mt-2 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to}</h3>
                    <h3 className="mt-2 text-white">QTY {quantity}</h3>
                  </div>
                </div>
                <p className='text-muted-new'>Make your Order in this Zone</p>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                  <img className="img-fluid icon" src={arrow_up_right} alt="down" />
                  <h6 className='text-white'>Target</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.target}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                  <img className="img-fluid icon" src={arrow_down_right} alt="up" />
                  <h6 className='text-white'>Stoploss</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.stoploss}</h3>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                  <i className="service-icon text-white p1" data-feather="clock" />
                  <h6 className='text-white'> Trailing Stop Loss</h6>
                </div>
                <h3 className="text-white"> ₹ {recommendation.stoploss}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                  <i className="service-icon text-white p1" data-feather="calendar" />
                  <h6 className='text-white'>Date (REC)</h6>
                </div>
                <h3 className="text-white"> {recommendation.date_of_recommandation}</h3>
              </div>
            </div>
          </div>
          <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
            <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
          </a>
        </div>
      </Link>
      </div>
    )}
    { selectedTab === 'stock' && recommendation.stock_type !== 'futures' && recommendation.stock_type !== 'options' && (
      <div>
      <Link to={`/recomm_details/${recommendation.id}`}>
        <div className="card-box mt-3">
          <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
            <div className="d-flex justify-content-between">
              <h5 className="fw-semibold">{recommendation.script_name}</h5>
              <img className="rec_img" src={apiImageUrl + '/recommandation_logo/' + recommendation.logo} alt="ellipse" />
            </div>
            <div className="">
              <div className="text-start">
                <div className="">
                  <div className='d-flex justify-content-between'>
                    <h3 className="mt-2 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to}</h3>
                    <h3 className="mt-2 text-white">QTY {quantity}</h3>
                  </div>
                </div>
                <p className='text-muted-new'>Make your Order in this Zone</p>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                  <img className="img-fluid icon" src={arrow_up_right} alt="down" />
                  <h6 className='text-white'>Target</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.target}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                  <img className="img-fluid icon" src={arrow_down_right} alt="up" />
                  <h6 className='text-white'>Stoploss</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.stoploss}</h3>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                  <i className="service-icon text-white p1" data-feather="clock" />
                  <h6 className='text-white'> Trailing Stop Loss</h6>
                </div>
                <h3 className="text-white"> ₹ {recommendation.stoploss}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                  <i className="service-icon text-white p1" data-feather="calendar" />
                  <h6 className='text-white'>Date (REC)</h6>
                </div>
                <h3 className="text-white"> {recommendation.date_of_recommandation}</h3>
              </div>
            </div>
          </div>
          <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
            <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
          </a>
        </div>
      </Link>
      </div>
    )}
    { selectedTab === 'future' && recommendation.stock_type === 'futures' && (
      <div>
      <Link to={`/recomm_details/${recommendation.id}`}>
        <div className="card-box mt-3">
          <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
            <div className="d-flex justify-content-between">
              <h5 className="fw-semibold">{recommendation.script_name}</h5>
              <img className="rec_img" src={apiImageUrl + '/recommandation_logo/' + recommendation.logo} alt="ellipse" />
            </div>
            <div className="">
              <div className="text-start">
                <div className="">
                  <div className='d-flex justify-content-between'>
                    <h3 className="mt-2 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to}</h3>
                    <h3 className="mt-2 text-white">QTY {quantity}</h3>
                  </div>
                </div>
                <p className='text-muted-new'>Make your Order in this Zone</p>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                  <img className="img-fluid icon" src={arrow_up_right} alt="down" />
                  <h6 className='text-white'>Target</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.target}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                  <img className="img-fluid icon" src={arrow_down_right} alt="up" />
                  <h6 className='text-white'>Stoploss</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.stoploss}</h3>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                  <i className="service-icon text-white p1" data-feather="clock" />
                  <h6 className='text-white'> Trailing Stop Loss</h6>
                </div>
                <h3 className="text-white"> ₹ {recommendation.stoploss}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                  <i className="service-icon text-white p1" data-feather="calendar" />
                  <h6 className='text-white'>Date (REC)</h6>
                </div>
                <h3 className="text-white"> {recommendation.date_of_recommandation}</h3>
              </div>
            </div>
          </div>
          <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
            <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
          </a>
        </div>
      </Link>
      </div>
    )}
     { selectedTab === 'options' && recommendation.stock_type === 'options' && (
      <div>
      <Link to={`/recomm_details/${recommendation.id}`}>
        <div className="card-box mt-3">
          <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
            <div className="d-flex justify-content-between">
              <h5 className="fw-semibold">{recommendation.script_name}</h5>
              <img className="rec_img" src={apiImageUrl + '/recommandation_logo/' + recommendation.logo} alt="ellipse" />
            </div>
            <div className="">
              <div className="text-start">
                <div className="">
                  <div className='d-flex justify-content-between'>
                    <h3 className="mt-2 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to}</h3>
                    <h3 className="mt-2 text-white">QTY {quantity}</h3>
                  </div>
                </div>
                <p className='text-muted-new'>Make your Order in this Zone</p>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                  <img className="img-fluid icon" src={arrow_up_right} alt="down" />
                  <h6 className='text-white'>Target</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.target}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                  <img className="img-fluid icon" src={arrow_down_right} alt="up" />
                  <h6 className='text-white'>Stoploss</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.stoploss}</h3>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                  <i className="service-icon text-white p1" data-feather="clock" />
                  <h6 className='text-white'> Trailing Stop Loss</h6>
                </div>
                <h3 className="text-white"> ₹ {recommendation.stoploss}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                  <i className="service-icon text-white p1" data-feather="calendar" />
                  <h6 className='text-white'>Date (REC)</h6>
                </div>
                <h3 className="text-white"> {recommendation.date_of_recommandation}</h3>
              </div>
            </div>
          </div>
          <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
            <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
          </a>
        </div>
      </Link>
      </div>
    )}
    </div>
  );
  
   
  };

  return (
    <section className="section-b-space">



      <div className="custom-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <button style={{
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: 'black',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                boxShadow: '2px 4px 6px 2px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s',
              }}>X</button>
            </Link>
          </div>
          <div className="title" >
            <h2 style={{paddingLeft:'50px'}}>Analysis</h2>
          </div>
          <div>
          <div className="custom-select-wrapper" >
  {/* Container for select and SVG */}
  <div  onClick={() => {
      if (selectRef.current) {
        selectRef.current.click(); // Trigger click event on select element
      }
    }}>
    {/* Select element */}
    <select
    
      value=""
      
      onChange={(e) => {
        const [status, type] = e.target.value.split('-');
        setFilterStatus(status);
        setFilterType(type);
        handleSort(status, type);
      }}
      className="custom-select"
    >
      <optgroup label="Filter by Status">
        <option value="all-all"></option>
        <option value={`all-${filterType}`}>All Status</option>
        <option value={`buy-${filterType}`}>Buy</option>
        <option value={`short-${filterType}`}>Short</option>
        <option value={`hold-${filterType}`}>Hold</option>
        <option value={`exit-${filterType}`}>Exit</option>
      </optgroup>
      <optgroup label="Filter by Type">
        
        <option value={`${filterStatus}-all`}>All Types</option>
        <option value={`${filterStatus}-intraday`}>Intraday</option>
        <option value={`${filterStatus}-swing`}>Swing</option>
        <option value={`${filterStatus}-multibeggar`}>Multibaggar</option>
        <option value={`${filterStatus}-mutualfund`}>Mutualfund</option>
        <option value={`${filterStatus}-investment`}>Investment</option>
        <option value={`${filterStatus}-short`}>Short</option>
        
      </optgroup>
       
      </select>
    {/* SVG icon */}
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      style={{
        position: 'absolute',
        right: '14px', // Adjust this value as needed for proper alignment
        top: '50%', // Adjust this value to vertically center the icon
        transform: 'translateY(-50%)', // Center the icon vertically
        cursor: 'pointer',
        zIndex: '-1' // Change cursor to pointer on hover
      }}
      onClick={handleSvgClick}
    >
      <path fill="#2b8b64" d="M22 18.605a.75.75 0 0 1-.75.75h-5.1a2.93 2.93 0 0 1-5.66 0H2.75a.75.75 0 1 1 0-1.5h7.74a2.93 2.93 0 0 1 5.66 0h5.1a.75.75 0 0 1 .75.75m0-13.21a.75.75 0 0 1-.75.75H18.8a2.93 2.93 0 0 1-5.66 0H2.75a.75.75 0 1 1 0-1.5h10.39a2.93 2.93 0 0 1 5.66 0h2.45a.74.74 0 0 1 .75.75m0 6.6a.74.74 0 0 1-.75.75H9.55a2.93 2.93 0 0 1-5.66 0H2.75a.75.75 0 1 1 0-1.5h1.14a2.93 2.93 0 0 1 5.66 0h11.7a.75.75 0 0 1 .75.75" />
    </svg>
    
  </div>
</div>




          </div>
        </div><br />
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center',marginTop:'-15px',marginBottom:'10px' }}>
            <span className='filter_tabs' style={{marginRight:'10px',fontSize:'8px'}}>Status: {filterStatus}</span>
            <span className='filter_tabs' style={{fontSize:'8px'}}>Type: {filterType}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 className={`stock_tabs ${selectedTab === 'all' ? 'active1' : ''}`} onClick={() => setSelectedTab('all')}>All</h2>
        <h2 className={`stock_tabs ${selectedTab === 'stock' ? 'active1' : ''}`} onClick={() => setSelectedTab('stock')}>Stock</h2>
            <h2 className={`stock_tabs ${selectedTab === 'future' ? 'active1' : ''}`} onClick={() => setSelectedTab('future')}>Future</h2>
            <h2 className={`stock_tabs ${selectedTab === 'options' ? 'active1' : ''}`} onClick={() => setSelectedTab('options')}>Options</h2>

        </div>



        {data.recommandation.map((recommendation, index) => (
          <RecommendationCard key={index} recommendation={recommendation} />
        ))}
      </div>
    </section>
  );
}

export default Recommendation1;
