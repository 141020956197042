import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Footer from './footer';
import SkeletonLoader from './SkeletonLoader';
import './BinaryTree.css'; // Import the new CSS file for custom styles
import { Tooltip } from 'react-tooltip';
import Modal from 'react-modal';
import profile from './assets/profile.png'
import profile1 from './assets/profile2.png'

function Binary_Tree() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get("id");
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalData1, setModalData1] = useState({});
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/fetch_tree_binary_api/${user_id}`);
        setData(response.data);
        console.log(response.data)
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user_id]);

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleOpenModal = (data) => {
    setModalData(data);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);

  };

  const handleCloseModal1 = () => {
    setModalIsOpen1(false);

  };

 

  const handleOpenModal1 = (data, e) => {
    setModalData1(data);
    setModalIsOpen1(true);
    setModalPosition({ top: e.clientY + 10, left: e.clientX + 10 });
  };
  

  const BinaryTree = ({ root }) => {
    if (!root) {
      return null;
    }

   

    return (
      <div className="binary-tree">
        <div className="tree-node">
          <center>
            <div>
            <div className='node-label' style={{ backgroundColor: root.all?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
            <img className='profile-img' src={profile} alt='profile' />
              <h4
                className='font-change'
                data-tip
                data-for={`tooltip-${root.self}`}
                onClick={() => handleOpenModal(root.all)}
              >
                {root.all.first_name}<br />
                {root.self}
              </h4>
              </div>
              <Tooltip  id={`tooltip-${root.self}`} place="top" effect="solid">
                <span>{`Name: ${root.all.name}`}</span>
              </Tooltip >
            </div>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={handleCloseModal}
              contentLabel="User Information"
              className="modal_tree"
              overlayClassName="modal_tree-overlay"
            >
              <h2>User Information</h2>
              <div style={{marginBottom:'5px'}}>
                <p style={{fontSize:'18px !important'}}><strong>User ID:</strong> {modalData.email}</p>
                <p style={{fontSize:'18px !important'}}><strong>Name:</strong> {modalData.first_name}</p>
                <p style={{fontSize:'18px !important'}}><strong>My Package:</strong> {modalData.my_package}</p>
                <div className='d-flex justify-content-between'>
                <p style={{marginRight:'10px',fontSize:'18px !important'}}><strong>Left Count:</strong> {modalData.left_count}</p>
                <p style={{fontSize:'18px !important'}}><strong>Right count:</strong> {modalData.right_count}</p>
                </div>
                <div className='d-flex justify-content-between'>
                <p style={{marginRight:'10px',fontSize:'18px !important'}}><strong>Left BV:</strong> {modalData.left_bv}</p>
                <p style={{fontSize:'18px !important'}}><strong>Right BV:</strong> {modalData.right_bv}</p>
                </div>
                <p style={{fontSize:'18px !important'}}><strong>Status:</strong> {modalData.is_active}</p>
                <p style={{fontSize:'18px !important'}}><strong>Activation Date:</strong> {modalData.activation_date}</p>
                {/* Add more fields as necessary */}
              </div>
              <button onClick={handleCloseModal} style={{width:'100%',padding:'5px',backgroundColor:'#622cfd',borderRadius:'10px',color:'white'}}>Close</button>
            </Modal>
          </center>
        </div>
        
           
        <div className="tree-row">
          <div className="tree-col">
            <div className="tree-node">
              <center>
                {root.child1_data?.email ? (<>
                  <Link to={`/binary_tree?id=${root.child1_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child1_data, e)}
                  onMouseLeave={handleCloseModal1} className="tree-link">
                    <div className='node-label tooltip1' style={{ backgroundColor: root.child1_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                    <img className='profile-img' src={profile} alt='profile' />
                   <h4 className='font-change'>
                      {root.child1_data?.first_name}<br/>
                      {root.child1_data?.email}
                      <span className="tooltiptext1">
                        User ID: {root.child1_data?.email} <br/>
                        Name: {root.child1_data?.first_name} <br/>
                        My Package: {root.child1_data?.my_package}<br/>
                        Left Count: {root.child1_data?.left_count} <br/>
                        Right count: {root.child1_data?.right_count} <br/>
                        Left BV: {root.child1_data?.left_bv} <br/>
                        Right BV: {root.child1_data?.right_bv} <br/>
                        Status: {root.child1_data?.is_active}<br/>
                        Activation Date: {root.child1_data?.activation_date}
                      </span>
                    </h4>
                    </div>
                  </Link>
                  
                        </>
                ) : (
                  <Link
                    // to={`https://www.app.smartfutureway.in/register?email=${root.self}&side=left`}
                    to={`https://www.smartfutureway.ukvalley.com/register?email=${root.self}&side=left`}
                    className="tree-link"
                  >
                     <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                     <img className='profile-img' src={profile1} alt='profile' />
                    <h5>Register</h5>
                    </div>
                  </Link>
                )}
              </center>
            </div>

            <div className="tree-children">
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child3_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child3_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child3_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                         <div className='node-label tooltip1' style={{ backgroundColor: root.child3_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                         <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child3_data?.first_name}<br/>
                          {root.child3_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child3_data?.email} <br/>
                        Name: {root.child3_data?.first_name} <br/>
                        My Package: {root.child3_data?.my_package}<br/>
                        Left Count: {root.child3_data?.left_count} <br/>
                        Right count: {root.child3_data?.right_count} <br/>
                        Left BV: {root.child3_data?.left_bv} <br/>
                        Right BV: {root.child3_data?.right_bv} <br/>
                        Status: {root.child3_data?.is_active}<br/>
                        Activation Date: {root.child3_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child1_data?.email}&side=left`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child1_data?.email}&side=left`}
                        className="tree-link"
                      >
                         <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                         <img className='profile-img' src={profile1} alt='profile' />
                        <h5>Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child4_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child4_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child4_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                         <div className='node-label tooltip1' style={{ backgroundColor: root.child4_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                         <img className='profile-img' src={profile} alt='profile' />
                        <h4  className='font-change'>
                        {root.child4_data?.first_name}<br/>
                          {root.child4_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child4_data?.email} <br/>
                        Name: {root.child4_data?.first_name} <br/>
                        My Package: {root.child4_data?.my_package}<br/>
                        Left Count: {root.child4_data?.left_count} <br/>
                        Right count: {root.child4_data?.right_count} <br/>
                        Left BV: {root.child4_data?.left_bv} <br/>
                        Right BV: {root.child4_data?.right_bv} <br/>
                        Status: {root.child4_data?.is_active}<br/>
                        Activation Date: {root.child4_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child1_data?.email}&side=right`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child1_data?.email}&side=right`}
                        className="tree-link"
                      >
                         <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                         <img className='profile-img' src={profile1} alt='profile' />
                        <h5>Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
            </div>
            <div className="tree-children">
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child7_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child7_data?.email || '#'}`}  onMouseEnter={(e) => handleOpenModal1(root.child7_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child7_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child7_data?.first_name}<br/>
                          {root.child7_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child7_data?.email} <br/>
                        Name: {root.child7_data?.first_name} <br/>
                        My Package: {root.child7_data?.my_package}<br/>
                        Left Count: {root.child7_data?.left_count} <br/>
                        Right count: {root.child7_data?.right_count} <br/>
                        Left BV: {root.child7_data?.left_bv} <br/>
                        Right BV: {root.child7_data?.right_bv} <br/>
                        Status: {root.child7_data?.is_active}<br/>
                        Activation Date: {root.child7_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child3_data?.email}&side=left`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child3_data?.email}&side=left`}
                        className="tree-link"
                      >
                         <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                         <img className='profile-img' src={profile1} alt='profile' />
                        <h5>Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child8_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child8_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child8_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child8_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child8_data?.first_name}<br/>
                          {root.child8_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child8_data?.email} <br/>
                        Name: {root.child8_data?.first_name} <br/>
                        My Package: {root.child8_data?.my_package}<br/>
                        Left Count: {root.child8_data?.left_count} <br/>
                        Right count: {root.child8_data?.right_count} <br/>
                        Left BV: {root.child8_data?.left_bv} <br/>
                        Right BV: {root.child8_data?.right_bv} <br/>
                        Status: {root.child8_data?.is_active}<br/>
                        Activation Date: {root.child8_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child3_data?.email}&side=right`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child3_data?.email}&side=right`}
                        className="tree-link"
                      >
                        <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                        <img className='profile-img' src={profile1} alt='profile' />
                        <h5  >Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child9_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child9_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child9_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child9_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child9_data?.first_name}<br/>
                          {root.child9_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child9_data?.email} <br/>
                        Name: {root.child9_data?.first_name} <br/>
                        My Package: {root.child9_data?.my_package}<br/>
                        Left Count: {root.child9_data?.left_count} <br/>
                        Right count: {root.child9_data?.right_count} <br/>
                        Left BV: {root.child9_data?.left_bv} <br/>
                        Right BV: {root.child9_data?.right_bv} <br/>
                        Status: {root.child9_data?.is_active}<br/>
                        Activation Date: {root.child9_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child4_data?.email}&side=left`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child4_data?.email}&side=left`}
                        className="tree-link"
                      >
                        <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                        <img className='profile-img' src={profile1} alt='profile' />
                        <h5  >Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child10_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child10_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child10_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child10_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child10_data?.first_name}<br/>
                          {root.child10_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child10_data?.email} <br/>
                        Name: {root.child10_data?.first_name} <br/>
                        My Package: {root.child10_data?.my_package}<br/>
                        Left Count: {root.child10_data?.left_count} <br/>
                        Right count: {root.child10_data?.right_count} <br/>
                        Left BV: {root.child10_data?.left_bv} <br/>
                        Right BV: {root.child10_data?.right_bv} <br/>
                        Status: {root.child10_data?.is_active}<br/>
                        Activation Date: {root.child10_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child4_data?.email}&side=right`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child4_data?.email}&side=right`}
                        className="tree-link"
                      >
                        <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                        <img className='profile-img' src={profile1} alt='profile' />
                        <h5  >Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div className="tree-col">
            <div className="tree-node">
              <center>
                {root.child2_data?.email ? (
                  <Link to={`/binary_tree?id=${root.child2_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child2_data, e)}
                  onMouseLeave={handleCloseModal1} className="tree-link">
                    <div className='node-label tooltip1' style={{ backgroundColor: root.child2_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                    <img className='profile-img' src={profile} alt='profile' />
                    <h4 className='font-change'>
                    {root.child2_data?.first_name}<br/>
                      {root.child2_data?.email}
                      <span className="tooltiptext1">
                        User ID: {root.child2_data?.email} <br/>
                        Name: {root.child2_data?.first_name} <br/>
                        My Package: {root.child2_data?.my_package}<br/>
                        Left Count: {root.child2_data?.left_count} <br/>
                        Right count: {root.child2_data?.right_count} <br/>
                        Left BV: {root.child2_data?.left_bv} <br/>
                        Right BV: {root.child2_data?.right_bv} <br/>
                        Status: {root.child2_data?.is_active}<br/>
                        Activation Date: {root.child2_data?.activation_date}
                      </span>
                    </h4>
                    </div>
                  </Link>
                ) : (
                  <Link
                    // to={`https://www.app.smartfutureway.in/register?email=${root.self}&side=right`}
                    to={`https://www.smartfutureway.ukvalley.com/register?email=${root.self}&side=right`}
                    className="tree-link"
                  >
                     <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                     <img className='profile-img' src={profile1} alt='profile' />
                    <h5 >Register</h5>
                    </div>
                  </Link>
                )}
              </center>
            </div>

            <div className="tree-children">
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child5_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child5_data?.email || '#'}`}  onMouseEnter={(e) => handleOpenModal1(root.child5_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child5_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child5_data?.first_name}<br/>
                          {root.child5_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child5_data?.email} <br/>
                        Name: {root.child5_data?.first_name} <br/>
                        My Package: {root.child5_data?.my_package}<br/>
                        Left Count: {root.child5_data?.left_count} <br/>
                        Right count: {root.child5_data?.right_count} <br/>
                        Left BV: {root.child5_data?.left_bv} <br/>
                        Right BV: {root.child5_data?.right_bv} <br/>
                        Status: {root.child5_data?.is_active}<br/>
                        Activation Date: {root.child5_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child2_data?.email}&side=left`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child2_data?.email}&side=left`}
                        className="tree-link"
                      >
                         <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                         <img className='profile-img' src={profile1} alt='profile' />
                        <h5>Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child6_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child6_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child6_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child6_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child6_data?.first_name}<br/>
                          {root.child6_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child6_data?.email} <br/>
                        Name: {root.child6_data?.first_name} <br/>
                        My Package: {root.child6_data?.my_package}<br/>
                        Left Count: {root.child6_data?.left_count} <br/>
                        Right count: {root.child6_data?.right_count} <br/>
                        Left BV: {root.child6_data?.left_bv} <br/>
                        Right BV: {root.child6_data?.right_bv} <br/>
                        Status: {root.child6_data?.is_active}<br/>
                        Activation Date: {root.child6_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child2_data?.email}&side=right`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child2_data?.email}&side=right`}
                        className="tree-link"
                      >
                        <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                        <img className='profile-img' src={profile1} alt='profile' />
                        <h5  >Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
            </div>
            <div className="tree-children">
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child11_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child11_data?.email || '#'}`}  onMouseEnter={(e) => handleOpenModal1(root.child11_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child11_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child11_data?.first_name}<br/>
                          {root.child11_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child11_data?.email} <br/>
                        Name: {root.child11_data?.first_name} <br/>
                        My Package: {root.child11_data?.my_package}<br/>
                        Left Count: {root.child11_data?.left_count} <br/>
                        Right count: {root.child11_data?.right_count} <br/>
                        Left BV: {root.child11_data?.left_bv} <br/>
                        Right BV: {root.child11_data?.right_bv} <br/>
                        Status: {root.child11_data?.is_active}<br/>
                        Activation Date: {root.child11_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child5_data?.email}&side=left`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child5_data?.email}&side=left`}
                        className="tree-link"
                      >
                         <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                         <img className='profile-img' src={profile1} alt='profile' />
                        <h5>Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child12_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child12_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child12_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child12_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child12_data?.first_name}<br/>
                          {root.child12_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child12_data?.email} <br/>
                        Name: {root.child12_data?.first_name} <br/>
                        My Package: {root.child12_data?.my_package}<br/>
                        Left Count: {root.child12_data?.left_count} <br/>
                        Right count: {root.child12_data?.right_count} <br/>
                        Left BV: {root.child12_data?.left_bv} <br/>
                        Right BV: {root.child12_data?.right_bv} <br/>
                        Status: {root.child12_data?.is_active}<br/>
                        Activation Date: {root.child12_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child5_data?.email}&side=right`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child5_data?.email}&side=right`}
                        className="tree-link"
                      >
                        <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                        <img className='profile-img' src={profile1} alt='profile' />
                        <h5  >Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child13_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child13_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child13_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child13_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child13_data?.first_name}<br/>
                          {root.child13_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child13_data?.email} <br/>
                        Name: {root.child13_data?.first_name} <br/>
                        My Package: {root.child13_data?.my_package}<br/>
                        Left Count: {root.child13_data?.left_count} <br/>
                        Right count: {root.child13_data?.right_count} <br/>
                        Left BV: {root.child13_data?.left_bv} <br/>
                        Right BV: {root.child13_data?.right_bv} <br/>
                        Status: {root.child13_data?.is_active}<br/>
                        Activation Date: {root.child13_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child6_data?.email}&side=left`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child6_data?.email}&side=left`}
                        className="tree-link"
                      >
                        <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                        <img className='profile-img' src={profile1} alt='profile' />
                        <h5  >Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
              <div className="tree-child">
                <div className="tree-node">
                  <center>
                    {root.child14_data?.email ? (
                      <Link to={`/binary_tree?id=${root.child14_data?.email || '#'}`} onMouseEnter={(e) => handleOpenModal1(root.child14_data, e)}
                      onMouseLeave={handleCloseModal1} className="tree-link">
                        <div className='node-label tooltip1' style={{ backgroundColor: root.child14_data?.is_active === 'active' ? 'lightgreen' : 'yellow' }}>
                        <img className='profile-img' src={profile} alt='profile' />
                        <h4 className='font-change'>
                        {root.child14_data?.first_name}<br/>
                          {root.child14_data?.email}
                          <span className="tooltiptext1">
                        User ID: {root.child14_data?.email} <br/>
                        Name: {root.child14_data?.first_name} <br/>
                        My Package: {root.child14_data?.my_package}<br/>
                        Left Count: {root.child14_data?.left_count} <br/>
                        Right count: {root.child14_data?.right_count} <br/>
                        Left BV: {root.child14_data?.left_bv} <br/>
                        Right BV: {root.child14_data?.right_bv} <br/>
                        Status: {root.child14_data?.is_active}<br/>
                        Activation Date: {root.child14_data?.activation_date}
                      </span>
                        </h4>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        // to={`https://www.app.smartfutureway.in/register?email=${root.child6_data?.email}&side=right`}
                        to={`https://www.smartfutureway.ukvalley.com/register?email=${root.child6_data?.email}&side=right`}
                        className="tree-link"
                      >
                        <div className='node-label' style={{ backgroundColor: 'red', color: 'white' }}>
                        <img className='profile-img' src={profile1} alt='profile' />
                        <h5  >Register</h5>
                        </div>
                      </Link>
                    )}
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="custom-container1">
      <Sidebar />
      <div className="title1">
        <center>
          <h2>My Binary Tree</h2>
        </center>
      </div>
      {data && data.rapid_data ? (
        <section>
          <BinaryTree root={data.rapid_data} />
        </section>
      ) : (
        <div className="no-data">No downline data available</div>
      )}
      <Footer />
    </div>
  );
}

export default Binary_Tree;
