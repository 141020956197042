import logo1 from './logo.svg';
import logo from './assets/theme_asset/logo1.png';
import React from 'react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import Modal from "./modal";
import { Link } from 'react-router-dom'; // Import the Link component
import auth6 from './assets/theme_asset/authentication/6.svg';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';



// Assuming you're using React Router and have a route set up for blog details

// Your other imports...

const MySwal = withReactContent(Swal);

function Register() {
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [data, setData] = useState(null);
  const [inputEmail, setInputEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status
  const [selectedCountryCode, setSelectedCountryCode] = useState("91");


  const location = useLocation();

  
  
  // Function component definition
  const searchParams1 = new URLSearchParams(location.search);
  const email1 = searchParams1.get('email');
  const side1 = searchParams1.get('side');
  const [formData, setFormData] = useState({
    

    name: '',
    email: '',
    mobile: '',
    sponcer_id: email1 || '',
    password: '',
    my_side: side1 || '',
    bbn_address: '',
    country_code: '',
  });

  const [selectedSide, setSelectedSide] = useState('');
    
    // State to determine whether the select element should be disabled

    // Effect hook to set the initial selected side based on the URL
   

    // Event handler to update the selected side
   
  
    
  const fetchData = async (sponcer_id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/userpageapi`);
      const userData = response.data.getalluser;
      setData(response.data);

      let found = false;
      let user_name = null;
     
      // Loop through each user object in the userData array
      userData.forEach(user => {
        // Check if the user's email matches the referral id
        if (user.email === sponcer_id) {
          found = true;
          user_name = user.first_name;
        }
      });
  
      if (found) {
        MySwal.fire({
          icon: 'success',
          title: 'Referral ID Found',
          text: `This referral ID is of ${user_name}`,
        });
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Referral ID Not Found',
          text: 'This referral ID does not exist in the database!',
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to check referral ID. Please try again later.',
      });
    }
  };
  
  const fetchData1 = async (sponcer_id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/userpageapi`);
      setData(response.data);    
    } catch (error) {
      console.error('Error checking referral ID:', error);
     
    }
  };

  useEffect(() => {
   fetchData1();
  }, []);

  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event.target.value);
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const [emailFromURL, setEmailFromURL] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const sideParam = searchParams.get('side');

    // If 'side' query parameter is available, set it as the selected side
    if (sideParam) {
        setSelectedSide(sideParam);
        
        
    }
    if (email) {
      const decodedEmail = decodeURIComponent(email);
      setEmailFromURL(decodedEmail);
      fetchData(decodedEmail);
    } else {
      setEmailFromURL(null); // Set emailFromURL to empty string
    }
  }, [location.search]);
 
 
  const handleSideChange = (event) => {
    setSelectedSide(event.target.value);
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
};


  const handleReferral = (e) => {
    const { name, value } = e.target;

    if (name === 'sponcer_id') {
      fetchData(value); // Call fetchData only when referral ID changes
    }

    setFormData({ ...formData, [name]: value });
  };


const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === 'my_side'){
      setSelectedSide(value);
      setFormData({ ...formData, [name]: value });
    }
    else{
      setFormData({ ...formData, [name]: value });
    }

    if (name === 'sponcer_id') {
     

      setFormData({ ...formData, [name]: value });
  } else {
      setFormData({ ...formData, [name]: value });
  }

    setFormData({ ...formData, [name]: value });
};



  // Function to submit the form data
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set isSubmitting to true when form is submitted

    try {
    
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      const response = await axios.post(`${apiBaseUrl}/register`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data for file upload
        },
      });
      const user = response.data.user;
      
      localStorage.setItem('modalMessage3', JSON.stringify({
        title: `Thank You For Registration`,
        content: `User Name ${user.first_name} Register Successfully and his User ID is ${user.email} \nand Password is ${user.pwd_open}\nLogin with This ID and Password`,
    }));
      
      
      navigate('/login'); // Redirect the user to the homepage after successful registration
    } catch (error) {
      console.error('Error registering user:', error);
      setModalMessage({
        title: 'Error',
        content: 'Failed to register. Please try again later or check the information you filled.',
      });
      setShowModal(true);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    setIsSubmitting(false);

  };

  // Function to check the referral ID against the database
  
  
  
  return (

    <body className="">
    
    <div>
       {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
       <div className="auth-header">
       <div className="custom-container">
       <div className='row'> 
          <div className='col-2'>
          <Link to="/login" style={{ textDecoration: 'none',marginTop:'15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{marginTop:'5px'}}>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </Link></div>
    <div className='col-10' ></div>
    </div></div>
    <div className="auth-content">
      <div>
        <h2>Welcome to Smart Future Way</h2>
        <h4 className="p-0">Fill up the form</h4>
      </div>
    </div>
  </div>
  {/* header end */}
  {/* login section start */}
  <form className="auth-form" onSubmit={handleSubmit}>
  <div className="custom-container">
  <div className="form-group">
        <label htmlFor="Refferal ID" className="form-label">
          Refferal ID
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="sponcer_id"
            name = "sponcer_id"
            value={emailFromURL} 
            placeholder="Enter Your Refferal Id"
            onChange={handleChange}
            onBlur={handleReferral}
          />
        </div>
      </div>
    
      <div className="form-group">
        <label htmlFor="name" className="form-label">
          Full name
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Enter your name"
            onChange={handleChange}
          />
        </div>
      </div>
     
      <div className="form-group">
        <label htmlFor="Mobile" className="form-label">
          Mobile
        </label>
       
     <div className='row'>
      <div className='col-4'>
      <div className="form-input">
      <select
        defaultValue={selectedCountryCode}
        onChange={handleCountryCodeChange}
        className="form-control"
        id="country_code"
        name="country_code"
      >
        {data && data.country.map((country, index) => (
          <option key={index} value={country.phonecode}>
            {country.iso} (+{country.phonecode})
          </option>
        ))}
      </select>
    </div>
          </div>
          <div className='col-8'>
          <div className="form-input">
          <input
            type="number"
            className="form-control"
            id="mobile"
            name="mobile"
            placeholder="Enter your Mobile"
            onChange={handleChange}
          />
        </div>
        </div>
        </div>
      </div>
      {/* <div className="form-group">
        <label htmlFor="image" className="form-label">
          Profile Image
        </label>
        <div className="form-input">
          <input
            type="file"
            className="form-control"
            id="image"
            name ="image"
            onChange={handleChange}
          />
        </div>
      </div> */}
      <div className="form-group">
        <label htmlFor="email" className="form-label">
          Email id
        </label>
        <div className="form-input">
          <input
            type="email"
            className="form-control"
            id="email"
            name = "email"
            placeholder="Enter Your Email"
            onChange={handleChange}
          />
        </div>
      </div>
      {/* <div className="form-group">
        <label htmlFor="name" className="form-label">
          BEP20 Address
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="bbn_address"
            name="bbn_address"
            placeholder="Enter your BEP20 Address"
            onChange={handleChange}
          />
        </div>
      </div> */}
      
      <div className="form-group">
        <label htmlFor="newpin" className="form-label">
          Password
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="password"
            name="password"
            placeholder="Enter Password"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="newpin" className="form-label">
          Confirm Password
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="con_password"
            name="con_password"
            placeholder="Confirm Password"
            required
          />
        </div>
      </div>
      <div className="form-group">
            <label htmlFor="side">Choose a side:</label>
            <div className="form-input">
                <select
                    id="side"
                    name="my_side"
                    className="form-control"
                    value={selectedSide} // Bind the selected value to the state
                    onBlur={handleSideChange} // Handle changes in the select element
                    onChange={handleChange}
                >
                    <option value="" disabled>select Your Side</option>
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                </select>
            </div>
        </div>
  
      
      <div className="remember-option mt-3">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            I agree to all terms &amp; condition
          </label>
        </div>
      </div>
      <button type="submit" className="btn theme-btn w-100" style={{color:'white'}} disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Sign up"}
          </button>
      <h4 className="signup">
        Already have an account ?<Link to="/login"> Sign in</Link>
      </h4>
    </div>
  </form>
       
        
      </div>
      </body>
  );
}

export default Register;
