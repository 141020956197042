import logo1 from './logo.svg';
import logo from './assets/theme_asset/logo.png';

import feather from 'feather-icons';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Link } from 'react-router-dom';

import { BiSolidLeftArrow } from 'react-icons/bi';



import deposite1 from './assets/theme_asset/deposit.png'
import atm from './assets/theme_asset/atm.png';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import './game.css';

import { useEffect } from 'react';

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Pagination from './react-pegination';






function Report() {

  const navigate = useNavigate();


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [filterDate, setFilterDate] = useState(null);
  const [filterMonth, setFilterMonth] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  
  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page
  const [bbnPrice, setBbnPrice] = useState(null);
  const [selectedTab, setSelectedTab] = useState('deposite');

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);



 

  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/AllReports/${id}?page=${pageNumber + 1}`);
      setData(response.data);
     

      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data && data.matching_report && data.matching_report.data) {
      let filtered = data.matching_report.data;
      
      if (filterDate) {
        filtered = filtered.filter(item => {
          const itemDate = new Date(item.date);
          return itemDate.toDateString() === filterDate.toDateString();
        });
      }

      if (filterMonth) {
        filtered = filtered.filter(item => {
          const itemDate = new Date(item.date);
          return itemDate.getFullYear() === filterMonth.getFullYear() && itemDate.getMonth() === filterMonth.getMonth();
        });
      }

      setFilteredData(filtered);
    }
  }, [data, filterDate, filterMonth]);


const handleMonthChange = (date) => {
    setFilterMonth(date);
    setFilterDate(null); // Reset the date filter
  };

  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage3');
      }

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }
    feather.replace();

    fetchData();
  }, []);


  
  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={false} showFooter={false} /> 

       )
    }

   
  
 
    return (


      <body className=''>
      <div >

     

      
      <div className="custom-container">
      <div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-150px'}}>
       <Link to="/" >
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{height:'60px'}}/>
          </div>
          </div>
          </div>
          <div className="title" style={{justifyContent:'center'}}>
        <h2 >All Report</h2>
    </div>
    <section>

    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', margin: '0 0 10px 0', overflowX: 'auto' }}>
    <h6 className={`stock_tabs1 ${selectedTab === 'deposite' ? 'active2' : ''}`} onClick={() => setSelectedTab('deposite')} >Deposite</h6>
    <h6 className={`stock_tabs1 ${selectedTab === 'activation' ? 'active2' : ''}`} onClick={() => setSelectedTab('activation')} >Activation</h6>
    <h6 className={`stock_tabs1 ${selectedTab === 'withdraw' ? 'active2' : ''}`} onClick={() => setSelectedTab('withdraw')} >Withdraw</h6>
    <h6 className={`stock_tabs1 ${selectedTab === 'daily' ? 'active2' : ''}`} onClick={() => setSelectedTab('daily')} >DailyPayout</h6>
    <h6 className={`stock_tabs1 ${selectedTab === 'binary' ? 'active2' : ''}`} onClick={() => setSelectedTab('binary')} >BinaryIncome</h6>
    <h6 className={`stock_tabs1 ${selectedTab === 'direct' ? 'active2' : ''}`} onClick={() => setSelectedTab('direct')} >DirectIncome</h6>
    <h6 className={`stock_tabs1 ${selectedTab === 'salary' ? 'active2' : ''}`} onClick={() => setSelectedTab('salary')} >SalaryIncome</h6>
    <h6 className={`stock_tabs1 ${selectedTab === 'roi' ? 'active2' : ''}`} onClick={() => setSelectedTab('roi')} >ROIIncome</h6>
    {/* Add more h6 elements for additional tabs */}
</div>
  <div className="custom-container">
  {/* <div style={{
color:'green',
textAlign:'center',
      }}>BBN Live Price: {bbnPrice && bbnPrice.rate ? (
        <span>${parseFloat(bbnPrice.rate).toFixed(8)}</span>
    ) : (
        'Loading...'
    )}</div> */}
   
{selectedTab === "deposite" && (
    <div>
    {data.deposite_report.data && data.deposite_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.deposite_report.data.map((deposite, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={deposite1} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{deposite.reason}</h5>
                  <h3 className={deposite.approval === 'pending' ? 'text-black' : deposite.approval === 'reject' ? 'error-color' : 'success-color'}>
             {deposite.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(deposite.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(deposite.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={deposite.approval === 'pending' ? 'text-black' : deposite.approval === 'reject' ? 'error-color' : 'success-color'}>
                {deposite.approval}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.deposite_report.links && (
        <Pagination
          pageCount={data.deposite_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}</div>
)}
{selectedTab === "activation" && (
    <div>
    {data.activation_report.data && data.activation_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.activation_report.data.map((activation_report, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={atm} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{activation_report.reason}</h5>
                  <h3 className={activation_report.approval === 'pending' ? 'text-black' : activation_report.approval === 'reject' ? 'error-color' : 'success-color'}>
             {activation_report.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(activation_report.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(activation_report.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={activation_report.approval === 'pending' ? 'text-black' : activation_report.approval === 'reject' ? 'error-color' : 'success-color'}>
                {activation_report.approval}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.activation_report.links && (
        <Pagination
          pageCount={data.activation_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}</div>
)}
{selectedTab === "withdraw" && (
    <div>
    {data.withdraw_report.data && data.withdraw_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.withdraw_report.data.map((withdraw_report, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={atm} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{withdraw_report.reason}</h5>
                  <h3 className={withdraw_report.approval === 'pending' ? 'text-black' : withdraw_report.approval === 'reject' ? 'error-color' : 'success-color'}>
             {withdraw_report.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(withdraw_report.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(withdraw_report.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={withdraw_report.approval === 'pending' ? 'text-black' : withdraw_report.approval === 'reject' ? 'error-color' : 'success-color'}>
                {withdraw_report.approval}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.withdraw_report.links && (
        <Pagination
          pageCount={data.withdraw_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}</div>
)}
 {selectedTab === "daily" && (
      <div>
         <div className="filter-container">
          <div className="filter-item">
            <label className="filter-label">
              Filter by Date:
            </label>
            <DatePicker 
              selected={filterDate} 
              onChange={date => {
                setFilterDate(date);
                setFilterMonth(null); // Reset the month filter
              }} 
              dateFormat="yyyy-MM-dd"
              className="filter-datepicker"
            />
          </div>
          <div className="filter-item">
            <label className="filter-label">
              Filter by Month:
            </label>
            <DatePicker
              selected={filterMonth}
              onChange={handleMonthChange}
              dateFormat="yyyy-MM"
              showMonthYearPicker
              className="filter-datepicker"
            />
          </div>
        
        </div>

        {filteredData && filteredData.length > 0 ? (
          <section>
            <div className="custom-container">
              {filteredData.map((matching, index) => (
                <div className="col-12" key={index}>
                  <div className="transaction-box">
                    <a href="#" className="d-flex gap-3">
                      <div className="transaction-image">
                        <img className="img-fluid" src={atm} alt="p1" />
                      </div>
                      <div className="transaction-details">
                        <div className="transaction-name">
                          <h5>Daily Payout</h5>
                          <h3 className={matching.approval === 'pending' ? 'text-black' : matching.approval === 'reject' ? 'error-color' : 'success-color'}>
                            {matching.total_amount}
                          </h3>
                        </div>
                        <div className="transaction-name">
                          <h5>{matching.date}</h5>
                          <h3 className={matching.approval === 'pending' ? 'text-black' : matching.approval === 'reject' ? 'error-color' : 'success-color'}>
                            Completed
                          </h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ))}
              {data.matching_report.links && (
                <Pagination
                  pageCount={data.matching_report.last_page}
                  onPageChange={handlePageClick}
                />
              )}
            </div>
          </section>
        ) : (
          <div style={{ textAlign: 'center' }}>No transactions available</div>
        )}
      </div>
    )}
{selectedTab === "binary" && (
    <div>
    {data.binary_report.data && data.binary_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.binary_report.data.map((binary_report, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={atm} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{binary_report.reason}</h5>
                  <h3 className={binary_report.approval === 'pending' ? 'text-black' : binary_report.approval === 'reject' ? 'error-color' : 'success-color'}>
             {binary_report.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(binary_report.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(binary_report.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={binary_report.approval === 'pending' ? 'text-black' : binary_report.approval === 'reject' ? 'error-color' : 'success-color'}>
                {binary_report.approval}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.binary_report.links && (
        <Pagination
          pageCount={data.binary_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}</div>
)}
{selectedTab === "direct" && (
    <div>
    {data.direct_income.data && data.direct_income.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.direct_income.data.map((direct_income, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={atm} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{direct_income.reason}</h5>
                  <h3 className={direct_income.approval === 'pending' ? 'text-black' : direct_income.approval === 'reject' ? 'error-color' : 'success-color'}>
             {direct_income.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(direct_income.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(direct_income.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={direct_income.approval === 'pending' ? 'text-black' : direct_income.approval === 'reject' ? 'error-color' : 'success-color'}>
                {direct_income.approval}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.direct_income.links && (
        <Pagination
          pageCount={data.direct_income.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}</div>
)}
{selectedTab === "salary" && (
    <div>
    {data.salary_report.data && data.salary_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.salary_report.data.map((salary, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={atm} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{salary.reason}</h5>
                  <h3 className={salary.approval === 'pending' ? 'text-black' : salary.approval === 'reject' ? 'error-color' : 'success-color'}>
             {salary.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(salary.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(salary.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={salary.approval === 'pending' ? 'text-black' : salary.approval === 'reject' ? 'error-color' : 'success-color'}>
                {salary.approval}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.salary_report.links && (
        <Pagination
          pageCount={data.salary_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}</div>
)}
{selectedTab === "roi" && (
    <div>
    {data.roi_report.data && data.roi_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.roi_report.data.map((roi, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={atm} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{roi.reason}</h5>
                  <h3 className={roi.approval === 'pending' ? 'text-black' : roi.approval === 'reject' ? 'error-color' : 'success-color'}>
             {roi.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(roi.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(roi.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={roi.approval === 'pending' ? 'text-black' : roi.approval === 'reject' ? 'error-color' : 'success-color'}>
                {roi.approval}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.roi_report.links && (
        <Pagination
          pageCount={data.roi_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}</div>
)}

          
       </div>
       </section>
       </div>
       </body>
  
  );
  }

  export default Report;
