import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "./modal";
import auth6 from "./assets/theme_asset/authentication/6.svg";
import axios from "axios";
import { useEffect } from "react";

// import "react-tabs/style/react-tabs.css";
// import { Box } from "@mui/material";

import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import { Tab, Tabs, Typography, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const RiskManagement = () => {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();


 

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [value, setValue] = useState("1");
  const [data, setData] = useState(null)

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
     

     
    } catch (error) {
    } finally {
    }
  };

  function getTrendBackground(status) {
    switch (status) {
      case "up":
        return "bg-green-status";
      case "down":
        return "bg-red-status";
      case "side":
        return "bg-yellow-status";
      default:
        return ""; // You can set a default class or leave it empty if none matches
    }
  }

  function getTrendIcon(status) {
    switch (status) {
      case "up":
        return "arrow-up-circle";
      case "down":
        return "arrow-down-circle";
      case "side":
        return "arrow-right-circle";
      default:
        return ""; // You can set a default class or leave it empty if none matches
    }
  }
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    // Replace icons after component is mounted
    

    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/login");
    } else {
      navigate("/risk_management");
    }

    fetchData();
  }, []);
  const [stockFormData, setStockFormData] = useState({
    capital:"",
    percentage: "",
    id:data && data.user && data.user.id,
  });

  const [futureFormData, setFutureFormData] = useState({
    capital:"",
    percentage: "",
    id:data && data.user && data.user.id,
  });

  const [optionsFormData, setOptionsFormData] = useState({
    capital:"",
    percentage: "",
    id:data && data.user && data.user.id,
  });
  const handleChangeStock = (event) => {
    const { name, value } = event.target;
    if (name === 'percentage') {
        const newValue = parseInt(value, 10);
        if (newValue < 0 || newValue > 10) {
            alert('Please enter a value between 0 and 10.');
            event.target.value = stockFormData[name];
            return;
        }
    }
    setStockFormData(prevState => ({ ...prevState, [name]: value }));
};


const handleChangeFuture = (event) => {
    const { name, value } = event.target;
    if (name === 'percentage') {
      const newValue = parseInt(value, 10);
      if (newValue < 0 || newValue > 10) {
          alert('Please enter a value between 0 and 10.');
          event.target.value = stockFormData[name];
          return;
      }
  }
    setFutureFormData(prevState => ({ ...prevState, [name]: value }));
};

const handleChangeOptions = (event) => {
    const { name, value } = event.target;
    if (name === 'percentage') {
      const newValue = parseInt(value, 10);
      if (newValue < 0 || newValue > 10) {
          alert('Please enter a value between 0 and 10.');
          event.target.value = stockFormData[name];
          return;
      }
  }
    setOptionsFormData(prevState => ({ ...prevState, [name]: value }));
};

const handleChangeOptions1 = (event) => {
  const { name, value } = event.target;
  if (name === 'percentage') {
    const newValue = parseInt(value, 10);
    if (newValue < 0 || newValue > 60) {
        alert('Please enter a value between 0 and 60.');
        event.target.value = stockFormData[name];
        return;
    }
}
  setOptionsFormData(prevState => ({ ...prevState, [name]: value }));
};




  

const handleSubmitStock = async (e) => {
  e.preventDefault();

  try {
    const capital = e.target.elements.capital.value;
    const id = e.target.elements.id.value;
    const percentage = e.target.elements.percentage.value;

    localStorage.setItem(
      "modalMessage1",
      JSON.stringify({
        title: "Risk Analyst Data Sent",
        content: "Stock Management Data Submitted",
      })
    );

    const response = await axios.post(`${apiBaseUrl}/stock_rsk`, {
      capital,
      id,
      percentage // Send the actual form data
    }, {
      headers: {
        'Content-Type': 'application/json', // Set content type to application/json for JSON data
      },
    });
    navigate('/');
  } catch (error) {
    console.log("Error while sending the Stock Management Data:", error);
    setModalMessage({
      title: "Error",
      content:
        "Failed to send Stock Management Data request. Please try again later or check the data you entered.",
    });
    setShowModal(true);
  }
};


const handleSubmitFuture = async (e) => {
  e.preventDefault();

  try {
    const capital = e.target.elements.capital.value;
    const id = e.target.elements.id.value;
    const percentage = e.target.elements.percentage.value;

    localStorage.setItem(
      "modalMessage2",
      JSON.stringify({
        title: "Risk Analyst Data Sent",
        content: "Future Management Data Submitted",
      })
    );
    const formDataToSend = stockFormData;

    const response = await axios.post(`${apiBaseUrl}/future_rsk`, {
      capital,
      id,
      percentage 
    }, {
      headers: {
        'Content-Type': 'application/json', // Set content type to application/json for JSON data
      },
    });
    navigate('/');
  } catch (error) {
    console.log("Error while sending the Future Management Data :", error);
    setModalMessage({
      title: "Error",
      content:
        "Failed to send Future Management Data request. Please try again later or check the data you entered.",
    });
    setShowModal(true);
  }
};

const handleSubmitOptions = async (e) => {
  e.preventDefault();

  try {
    const capital = e.target.elements.capital.value;
    const id = e.target.elements.id.value;
    const percentage = e.target.elements.percentage.value;

    localStorage.setItem(
      "modalMessage3",
      JSON.stringify({
        title: "Risk Analyst Data Sent",
        content: "Options Management Data Submitted",
      })
    );

    const response = await axios.post(`${apiBaseUrl}/option_rsk`, {
      capital,
      id,
      percentage
    }, {
      headers: {
        'Content-Type': 'application/json', // Set content type to application/json for JSON data
      },
    });
    navigate('/');
  } catch (error) {
    console.log("Error while sending the Options Management Data :", error);
    setModalMessage({
      title: "Error",
      content:
        "Failed to send Options Management Data request. Please try again later or check the data you entered.",
    });
    setShowModal(true);
  }
};
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}

      <div className="auth-header">
        <div>
          <Link to="/" style={{ textDecoration: "none" }}>
            <button
              style={{
                padding: "8px 16px",
                color: "white",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </Link>
        </div>

        <img className="img-fluid img" src={auth6} alt="v1" />

        <div className="auth-content">
          <div>
            <h1 className="text-white"> Risk Management</h1>
          </div>
        </div>
      </div>

      <div>
    <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => handleChange(newValue)}
                    aria-label="lab API tabs example"
                >
                    <Tab label="Stock" value="1" />
                    <Tab label="Future" value="2" />
                    <Tab label="Options" value="3" />
                </TabList>
            </Box>
            <TabPanel value="1">
                <div>
                    <form className="auth-form" onSubmit={handleSubmitStock}>
                        <div className="custom-container">
                            <h1 className="" style={{ color: "black" }}>
                                Stock Management
                            </h1>

                            <div className="form-group">
                                <label htmlFor="DOB" className="form-label">
                                    Enter Capital Amount
                                </label>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="capital"
                                        name="capital"
                                        placeholder="Enter Your Capital Amonut"
                                        onChange={handleChangeStock}
                                    />
                                    <input
                                        type="hidden"
                                        className="form-control"
                                        id="id"
                                        name="id"
                                        defaultValue={data && data.user && data.user.id}
                                        placeholder="Enter Your Capital Amonut"
                                        onChange={handleChangeStock}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputusername" className="form-label">
                                    Enter Percentage(0.1%-10%)
                                </label>

                                <div className="form-input">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="percentage"
                                        name="percentage"
                                        placeholder="Select Percentage"
                                        onChange={handleChangeStock}
                                    />
                                    <span style={{color:'red',fontSize:'12px'}}>Please Enter till 5% only else Enter at your own risk</span>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="btn theme-btn w-100"
                                style={{ color: "white" }}
                            >
                                Submit
                            </button>
                        </div>

                {data && data.Stock_rsk_report && <section>
        <div className="custom-container">
          
          <div className="row gy-3">
            {data.Stock_rsk_report.map((stock, index) => (
              <div className="col-12">
                <div className="transaction-box">
                  <Link to="#" className="d-flex gap-3">
                   
                    <div className="transaction-details" style={{width:'100%'}}>
                      <div className="transaction-name">
                        <h2>{stock.reason === 'capital_stock' ? 'Capital for Stock' : 'no reason'}</h2>
                        <h6 >₹{stock.amount}</h6>
                      </div>

                      <div className="d-flex justify-content-between">
                      <h5 className="light-text"> {new Date(stock.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}</h5>
                      <h6 >{stock.status}%</h6>
                    </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      }
                          </form>
                </div>
            </TabPanel>
            <TabPanel value="2">
                <div>
                    <form className="auth-form" onSubmit={handleSubmitFuture}>
                        <div className="custom-container">
                            <h1 className="" style={{ color: "black" }}>
                                Future Management
                            </h1>

                            <div className="form-group">
                                <label htmlFor="DOB" className="form-label">
                                    Enter Capital Amount
                                </label>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="capital"
                                        name="capital"
                                        placeholder="Enter Your Capital Amonut"
                                        onChange={handleChangeFuture}
                                    />
                                    <input
                                        type="hidden"
                                        className="form-control"
                                        id="id"
                                        name="id"
                                        defaultValue={data && data.user && data.user.id}
                                        placeholder="Enter Your Capital Amonut"
                                        onChange={handleChangeStock}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputusername" className="form-label">
                                    Enter Percentage(0.1%-10%)
                                </label>

                                <div className="form-input">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="percentage"
                                        name="percentage"
                                        placeholder="Select Percentage"
                                        onChange={handleChangeFuture}
                                    />
                                    <span style={{color:'red',fontSize:'12px'}}>Please Enter till 5% only else Enter at your own risk</span>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="btn theme-btn w-100"
                                style={{ color: "white" }}
                            >
                                Submit
                            </button>
                        </div>
                  
                        {data && data.future_rsk_report && <section>
        <div className="custom-container">
          
          <div className="row gy-3">
            {data.future_rsk_report.map((future, index) => (
              <div className="col-12">
                <div className="transaction-box">
                  <Link to="#" className="d-flex gap-3">
                   
                    <div className="transaction-details" style={{width:'100%'}}>
                      <div className="transaction-name">
                        <h2>{future.reason === 'capital_future' ? 'Capital for Future' : 'no reason'}</h2>
                        <h6 >₹{future.amount}</h6>
                      </div>

                      <div className="d-flex justify-content-between">
                      <h5 className="light-text"> {new Date(future.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}</h5>
                      <h6 >{future.status}%</h6>
                    </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      }
      </form>
      </div>
            </TabPanel>
            <TabPanel value="3">
                <div>
                    <form className="auth-form" onSubmit={handleSubmitOptions}>
                        <div className="custom-container">
                            <h1 className="" style={{ color: "black" }}>
                                Options Management
                            </h1>

                            <div className="form-group">
                                <label htmlFor="DOB" className="form-label">
                                    Enter Capital Amount
                                </label>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="capital"
                                        name="capital"
                                        placeholder="Enter Your Capital Amonut"
                                        onChange={handleChangeOptions1}
                                    />
                                    <input
                                        type="hidden"
                                        className="form-control"
                                        id="id"
                                        name="id"
                                        defaultValue={data && data.user && data.user.id}
                                        placeholder="Enter Your Capital Amonut"
                                        onChange={handleChangeStock}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputusername" className="form-label">
                                    Enter Percentage(0.1%-60%)
                                </label>

                                <div className="form-input">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="percentage"
                                        name="percentage"
                                        placeholder="Select Percentage"
                                        onChange={handleChangeOptions}
                                    />
                                    <span style={{color:'red',fontSize:'12px'}}>Please Enter till 20% only else Enter at your own risk</span>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="btn theme-btn w-100"
                                style={{ color: "white" }}
                            >
                                Submit
                            </button>
                        </div>
                    
                        {data && data.option_rsk_report && <section>
        <div className="custom-container">
          
          <div className="row gy-3">
            {data.option_rsk_report.map((option, index) => (
              <div className="col-12">
                <div className="transaction-box">
                  <Link to="#" className="d-flex gap-3">
                   
                    <div className="transaction-details" style={{width:'100%'}}>
                      <div className="transaction-name">
                        <h2>{option.reason === 'capital_option' ? 'Capital for Options' : 'no reason'}</h2>
                        <h6 >₹{option.amount || 0}</h6>
                      </div>

                      <div className="d-flex justify-content-between">
                      <h5 className="light-text"> {new Date(option.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}</h5>
                      <h6 >{option.status || 0}%</h6>
                    </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      }
      </form>
                </div>
            </TabPanel>
        </TabContext>
    </Box>
</div>

    </div>
  );
};

export default RiskManagement;

