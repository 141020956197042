import React, { useEffect, useState } from "react";
import feather from "feather-icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

import "./App.css";

import logo1 from "./assets/theme_asset/logo/logo.png";
import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";

function Sidebar() {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [data, setData] = useState("");
  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
      

    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    // Check if token exists
    const token = localStorage.getItem("token");
    if (!token) {
      // If no token exists, navigate to login
      navigate("/login");
    }

    // Replace icons after component is mounted
    feather.replace();
    fetchData();
  }, []);

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page by changing the URL
    window.location.href = '/login';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };


  const userid = data && data.user && data.user.email;

    useEffect(() => {
        const timerId = setInterval(() => {
            setRemainingTime(getRemainingTime());
        }, 1000);

        return () => clearInterval(timerId);
    }, []);

    function getRemainingTime() {
        const now = new Date();
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999); // Set end of day time to 23:59:59.999
        const difference = endOfDay - now;
        return difference >= 0 ? formatTime(difference) : '00:00:00'; // Ensure the timer doesn't go negative
    }

    function formatTime(milliseconds) {
        const hours = Math.floor(milliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }


  return (
    <div>
      <div
        className={`offcanvas sidebar-offcanvas offcanvas-start${
          isSidebarOpen ? " show" : ""
        }`}
        tabIndex={-1}
        id="offcanvasLeft"
      >
        <div className="offcanvas-header sidebar-header">
          <a onClick={closeSidebar}>
            <i className="categories-icon" data-feather="x" />
          </a>

          <div className="sidebar-logo" style={{marginTop:'-30px'}}>
            <img className="img-fluid logo" src={logo1} alt="logo" />
          </div>

          <div className="balance">
            <img className="img-fluid balance-bg" src={auth_bg} alt="auth-bg" />
            <h2>{data && data.user && data.user.first_name}</h2>
            <h5>Welcome to Smart Future Way</h5>
          </div>
        </div>
        <div className="offcanvas-body">
          <div className="sidebar-content">
            <ul className="link-section">
              <li>
                <Link to="/" className="pages">
                  <i className="sidebar-icon" data-feather="home" />
                  <h3>Home</h3>
                </Link>
              </li><hr/>
              <li>
              <Link to="/team" className="pages">
                  <i className="sidebar-icon" data-feather="users" />
                  <h3>Team</h3>
                </Link>
              </li><hr/>
              <li>
              <Link to="/my_direct" className="pages">
                  <i className="sidebar-icon" data-feather="users" />
                  <h3>My Direct Team</h3>
                </Link>
              </li><hr/>
              <li>
              <Link to={`/binary_tree?id=${userid}`} className="pages">
                  <i className="sidebar-icon" data-feather="users" />
                  <h3>Binary Tree</h3>
                </Link>
              </li><hr/>
              <li>
                <Link to="/profile" className="pages">
                  <i className="sidebar-icon" data-feather="user" />
                  <h3>Profile</h3>
                </Link>
              </li><hr/>
              <li>
                <Link to="/activation" className="pages">
                  <i className="sidebar-icon" data-feather="package" />
                  <h3>Activation</h3>
                </Link>
              </li><hr/>
              <li>
                <Link to="/deposite" className="pages">
                  <i className="sidebar-icon" data-feather="briefcase" />
                  <h3>Deposite</h3>
                </Link>
              </li><hr/>

              <li>
                <Link to="/report" className="pages">
                  <i className="sidebar-icon" data-feather="command" />
                  <h3>All Reports</h3>
                </Link>
              </li><hr/>
              
              <li>
                <Link to="/withdraw" className="pages">
                  <i className="sidebar-icon" data-feather="dollar-sign" />
                  <h3>Withdraw</h3>
                </Link>
              </li><hr/>
             
              <li>
                <Link to="/about" className="pages">
                  <i className="sidebar-icon" data-feather="info" />
                  <h3>About us</h3>
                </Link>
              </li><hr/>
            
              <li>
                <Link to="/terms" className="pages">
                  <i className="sidebar-icon" data-feather="file-text" />
                  <h3>Terms and Condition</h3>
                </Link>
              </li><hr/>
  
             
              <li>
                <Link to="#" onClick={handleLogout} className="pages">
                  <i className="sidebar-icon" data-feather="log-out" />
                  <h3>Log out</h3>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="section-t-space" style={{paddingTop:'10px'}}>
        <div className="custom-container">
          <div className="header-panel" style={{height:'80px'}}>
            <button className="sidebar-btn" onClick={toggleSidebar}>
              <i className="menu-icon" data-feather="menu" />
            </button>
            <img className="img-fluid logo" src={logo1} alt="logo" />
            <div className="notification">
                        <div className="notification-icon" data-feather="bell" />
                        <div style={{color:'white'}}>{remainingTime}</div>
                    </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Sidebar;
