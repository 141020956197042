import logo1 from './logo.svg';
import logo from './assets/theme_asset/logo.png';

import feather from 'feather-icons';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Link } from 'react-router-dom';

import { BiSolidLeftArrow } from 'react-icons/bi';


import token1 from './assets/theme_asset/token/21.png';
import token2 from './assets/theme_asset/token/22.png';
import token3 from './assets/theme_asset/token/23.png';
import token4 from './assets/theme_asset/token/24.png';
import token5 from './assets/theme_asset/token/25.png';
import token6 from './assets/theme_asset/token/26.png';
import token7 from './assets/theme_asset/token/27.png';
import token8 from './assets/theme_asset/token/28.png';
import token9 from './assets/theme_asset/token/29.png';
import token10 from './assets/theme_asset/token/30.png';
import color1 from './assets/theme_asset/token/color1.png';
import color2 from './assets/theme_asset/token/color2.png';
import color3 from './assets/theme_asset/token/color3.png';
import deposite1 from './assets/theme_asset/deposit.png'



import './game.css';

import { useEffect } from 'react';

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Pagination from './react-pegination';






function Salary() {

  const navigate = useNavigate();


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  
  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page
  const [bbnPrice, setBbnPrice] = useState(null);

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}?page=${pageNumber + 1}`);
      setData(response.data);
     

      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage3');
      }

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/salary');
    }
    feather.replace();

    fetchData();
  }, []);


  
  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={false} showFooter={false} /> 

       )
    }

   
  
 
    return (


      <body className=''>
      <div >
      
      <div className="custom-container">
      <div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-150px'}}>
       <Link to="/profile" >
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{height:'60px'}}/>
          </div>
          </div>
          </div>
      
    <section>
  <div className="custom-container">
  {/* <div style={{
color:'green',
textAlign:'center',
      }}>BBN Live Price: {bbnPrice && bbnPrice.rate ? (
        <span>${parseFloat(bbnPrice.rate).toFixed(8)}</span>
    ) : (
        'Loading...'
    )}</div> */}
    <div className="title" style={{justifyContent:'center'}}>
        <h2 >Salary History</h2>
    </div>

    {data.level_report.data && data.level_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.level_report.data.map((deposite, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={deposite1} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{deposite.reason}</h5>
                  <h3 className={deposite.status === 'pending' ? 'text-black' : deposite.status === 'reject' ? 'error-color' : 'success-color'}>
             {deposite.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(deposite.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(deposite.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={deposite.status === 'pending' ? 'text-black' : deposite.status === 'reject' ? 'error-color' : 'success-color'}>
                {deposite.status}</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.level_report.links && (
        <Pagination
          pageCount={data.level_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}

          
       </div>
       </section>
       </div>
       </body>
  
  );
  }

  export default Salary;
