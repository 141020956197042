import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';



// Assuming you're using React Router and have a route set up for blog details






import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function Recomm_details() {
  const { id } = useParams();
  const [RecommandationPost, setRecommandationPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const fetchData = async () => {
    
    try {
      const response = await axios.get(`${apiBaseUrl}/recommandationapi/${id}`);
      setRecommandationPost(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  function getStatusClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green-status';
      case 'short':
        return 'bg-red-status';
      case 'hold':
        return 'bg-yellow-status';
      case 'exit':
        return 'bg-black-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }
  
  return (
    <div>
      <div className="auth-header" style={{height:'150px'}}>
      <div>
              <Link to="/recommandation" style={{ textDecoration: 'none' }}>
                <button style={{
                  padding: '8px 16px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}>X</button>
              </Link>
            </div>
        
        <div className="auth-content" style={{justifyContent:'center'}}>
          <div>
            <center>
            <h2>Recommandation details</h2>
            <h4 className="p-0">Brefily discribed recommandation</h4>
            </center></div>
        </div>
      </div>
      <section className="section-b-space">
        <div className="custom-container">
          
          {RecommandationPost && (
            <>
              <div className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
            <div className="d-flex gap-3">
              <div className="bill-icon">
                <img className="img-fluid icon" src={apiImageUrl+'/recommandation_logo/'+RecommandationPost.recommandation.logo} alt="p6" />
              </div>
              <div className="bill-details">
                <h6 className="dark-text">{RecommandationPost.recommandation.script_name} |<span className="text-muted"> {RecommandationPost.recommandation.stock_type}</span></h6>
                <h6 className="light-text mt-2"><span className="">BZ: (₹{RecommandationPost.recommandation.price_band_from} - ₹{RecommandationPost.recommandation.price_band_to})</span> | {RecommandationPost.recommandation.duration}</h6>
              </div>
            </div>
            <div className="bill-price">
              <h5>T: ₹{RecommandationPost.recommandation.target} | SL: ₹{RecommandationPost.recommandation.stoploss} </h5>
              <a href="#pay" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(RecommandationPost.recommandation.status)}`}>{RecommandationPost.recommandation.status}</a>
            </div>
          </div>
          <br />
          <h3 style={{paddingTop:'10px', textAlign:'center'}}>{RecommandationPost.recommandation.script_name}</h3>
          <p><div>{RecommandationPost.recommandation.status_desc}</div></p>
            </>
          )}
        </div>
      </section>
    </div>
  );
}
export default Recomm_details;




